
const findPrice = function(row) {
  return Number(row.dataset.price) || -1
}

const hideRows = function(pricesHash) {
  $('.table tbody tr[data-price]').each(function() {
    let price = findPrice(this)

    if (pricesHash.min <= price && price < pricesHash.max) {
      this.classList.remove('hidden')
    } else {
      this.classList.add('hidden')
    }
  })
}

const loadPricesFromHash = function(pricesHash) {
  let allSelectedPrices = []

  Object.keys(pricesHash).forEach(key => {
    let section = pricesHash[key]

    if (section.selected) {
      allSelectedPrices.push(section.maxprice)
      allSelectedPrices.push(section.minprice)
    }
  })

  if (allSelectedPrices.length == 0) {
    return { min: -1, max: Infinity }
  } else {
    return { min: Math.min(...allSelectedPrices), max: Math.max(...allSelectedPrices) }
  }
}

const updateTableHeaderClasses = function() {
  $('.table').each(function() {
    if ($(this).find('tbody tr.hidden').length == $(this).find('tbody tr').length) {
      this.classList.add('hidden')
    } else {
      this.classList.remove('hidden')
    }
  })
}

export default {
  updateDisaplyedRows(pricesHash) {
    hideRows(loadPricesFromHash(pricesHash))
    updateTableHeaderClasses()
  }
}
