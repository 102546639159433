$(document)
  .on('turbo:load', function() {
    if($('.dropdown .dropdown-menu').find('.dropdown-border-row').length == 0){
      $('.dropdown .dropdown-menu:last-child').append('<div class="row dropdown-border-row"><div class="col-md-12 dropdown-border-cols"></div></div>')
    }
    setHeight()
    $('.navbar-nav li a').each(function() {
      var activeLink = $(this).attr('href')
      if(activeLink !== '#' && window.location.href.indexOf(activeLink) > -1 ) {
        $(this).addClass('active-link')
      }
    })

    setTimeout(function(){
      if( $(document).scrollTop() >= 80 ){
        $('.navbar').css('min-height','50px').addClass('scrolled')
        toggleCookies()
        toggleScrolled()
      }
    }, 10)

  })

const isMobileNav = () => $('.check-for-mobile').css('float') === 'none'
const isTablet = () => window.outerWidth > 767 && window.outerWidth < 1025

function setHeight() {
  if($('.cookie-consent-dropdown').length && !isMobileNav()) {
    $('.navbar-default .navbar-nav .dropdown .dropdown-menu').addClass('with-cookies')
  } else {
    $('.navbar-default .navbar-nav .dropdown .dropdown-menu').removeClass('with-cookies')
  }
}

function toggleCookies() {
  if($('.navbar-default').hasClass('scrolled')) {
    $('.nav-cookies').slideUp(80)
  } else {
    $('.nav-cookies').slideDown(80)
    setHeight()
  }
}

function toggleScrolled() {
  if($('.navbar-default').hasClass('scrolled')) {
    $('.navbar-brand').addClass('scrolled')
    $('.navbar-collapse').addClass('scrolled')
    $('.navbar-short').addClass('scrolled')
    $('.navbar-nav').addClass('scrolled')
    $('body').addClass('navbar-is-collapsed')
    $('#packages-filter').find('.mobile-packages-filter').addClass('scrolled')

    if(!isMobileNav() && !isTablet())
      $('.f1-top-bar').slideUp(80)

  } else {
    $('.navbar-brand').removeClass('scrolled')
    $('.navbar-collapse').removeClass('scrolled')
    $('.navbar-short').removeClass('scrolled')
    $('.navbar-nav').removeClass('scrolled')
    $('body').removeClass('navbar-is-collapsed')
    $('#packages-filter').find('.mobile-packages-filter').addClass('scrolled')

    if(!isMobileNav() && !isTablet())
      $('.f1-top-bar').slideDown(80)
  } 
}

// let TIMER = false
$(document)

  .on('hide.bs.dropdown', '.navbar-nav', function(event) {
    if(isTablet()) {
      event.stopPropagation()
      event.preventDefault()
      $(event.target).siblings().removeClass('hide')
      $('.navbar .open').removeClass('open')
    }
  })

  .on('show.bs.dropdown', '.navbar-nav', function(event) {
    if(isTablet()) {
      $(event.target).siblings().addClass('hide')
    }
  })

  .on('click', '.navbar-default .navbar-nav > .dropdown', function(event){
    if(!isMobileNav() && !isTablet()) {
      // TIMER=false
      $(event.currentTarget).addClass('open', 'dropdown-hover')
    }
  })

//.on('mouseleave', '.navbar-default .navbar-nav > .dropdown', function(event){
// if(!isMobileNav() && !isTablet()) {
//   setTimeout(function(){
//     if(document.getElementsByClassName('open').length > 1){
//       $(event.currentTarget).removeClass('open')
//     }
//     else {
//       TIMER=true
//       setTimeout(function(){
//         if(TIMER==true){
//           $(event.currentTarget).removeClass('open')
//         }
//       }, 1000)
//     }
//   }, 1)
// }
//})

  .on('click', '.navbar-short .dropdown > .dropdown-toggle', function(event) {
    if(!isMobileNav() && !isTablet()) {
      $(event.currentTarget).parent().find('.dropdown-menu').addClass('display')
    }
  })


  .on('click', '.navbar-nav .dropdown.open > .dropdown-toggle', function(event) {    
    if(isTablet()) {
      let parent = $(event.currentTarget).parent()
      parent.removeClass('open').siblings()
      $('navbar-nav .divider').addClass('hide')
      event.stopPropagation()
      event.preventDefault()
    }
  })

  .on('scroll', function() {
    if($('.cookie-consent-dropdown').length) {
      toggleCookies()
    }
    if(!isMobileNav() && !isTablet()) {
      toggleScrolled()
    }
    setHeight()
  })

  .on('turbo:load', function() {
    if(isMobileNav() || isTablet()) {
      $('.f1-top-bar').hide()
      $('.mobile-top-bar .f1-top-bar').show()
    }
  })

  .on('click', '.navbar-nav .dropdown.open > .dropdown-toggle', function(event) {
    if(isMobileNav()) {
      let parent = $(event.currentTarget).parent()
      parent.removeClass('open').siblings()
      event.stopPropagation()
      event.preventDefault()
    }
  })

  .on('click', '#search-button', () => $('#search').focus())
  .on('scroll click turbo:load', function(event) {
    if(!isMobileNav && !$.contains($('.navbar-collapse')[0], event.target)) {
      $('.navbar-collapse').collapse('hide')
      $('.navbar .open:not(.search-dropdown)').removeClass('open')
    }
  })

  .on('click', '.cookie-accept', function(){
    setHeight()
  })

  .on('click', '.navbar-short .navbar-toggle', function() {
    $('.nav-overlay').toggleClass('open')
    $('body').toggleClass('overflow')
  })

  .on('click', '#navbar-default .menu-item.navbar-item.nav-item', () => {
    if(isMobileNav() || isTablet())
      $('#navbar-default button#dropdownToggler.navbar-toggle').click()
  })

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.navbar-default .navbar-nav > .dropdown').forEach( (elem) => {
    elem.addEventListener('click', (event) => {
      if(!isMobileNav() && !isTablet()) {
        $(event.currentTarget).find('.dropdown-menu').addClass('display')
      }
    })
  })
})

document.addEventListener('turbo:load', () => {
  let navbar = document.querySelector('.navbar-collapse')
  let toggle = document.querySelector('.navbar-toggle')
  if(navbar) {
    navbar.classList.remove('in')
  }
  if(toggle) {
    toggle.classList.add('collapsed')
  }
})