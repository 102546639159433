<template>
  <ul
    class="partner-link-list"
  >
    <li class="menu-item">
      <a
        href="https://f1tv.formula1.com/"
        target="_blank"
      >
        <img src="../../../assets/images/f1/f1-tv-logo.svg">
        <img
          class="external-link"
          src="../../../assets/images/f1/f1e-external-link.svg"
        >
      </a>
    </li>
    <li class="menu-item">
      <a
        href="https://www.f1authentics.com/"
        target="_blank"
      >
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="menu-item-data"
          v-html="$t('topBar.authentics')"
        />
        <!--eslint-enable-->
        <img
          class="external-link"
          src="../../../assets/images/f1/f1e-external-link.svg"
        >
      </a>
    </li>
    <li class="menu-item">
      <a
        href="https://f1store2.formula1.com/"
        target="_blank"
      >
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="menu-item-data"
          v-html="$t('topBar.store')"
        />
        <!--eslint-enable-->
        <img
          class="external-link"
          src="../../../assets/images/f1/f1e-external-link.svg"
        >
      </a>
    </li>
    <li class="menu-item">
      <a
        href="https://tickets.formula1.com/"
        target="_blank"
      >
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="menu-item-data"
          v-html="$t('topBar.tickets')"
        />
        <!--eslint-enable-->
        <img
          class="external-link"
          src="../../../assets/images/f1/f1e-external-link.svg"
        >
      </a>
    </li>
    <li class="menu-item">
      <a
        href="https://tickets.formula1.com/en/4281-formula-one-paddock-club-tickets/"
        target="_blank"
      >
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="menu-item-data"
          v-html="$t('topBar.hospitality')"
        />
        <!--eslint-enable-->
        <img
          class="external-link"
          src="../../../assets/images/f1/f1e-external-link.svg"
        >
      </a>
    </li>
    <li class="menu-item f1-experiences">
      <!-- eslint-disable vue/no-v-html -->
      <a
        href="https://www.f1experiences.com/"
        v-html="$t('topBar.experiences')"
      />
      <!--eslint-enable-->
    </li>
    <li class="menu-item partner-links">
      <a
        href="https://www.formula1.com/"
        target="_blank"
        class="f1-link"
      >F1<sup>&reg;</sup></a>

      <a
        href="https://www.fiaformula2.com/"
        target="_blank"
      >F2<sup>TM</sup></a>

      <a
        href="https://www.fiaformula3.com/"
        target="_blank"
      >F3<sup>TM</sup></a>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      scrolled: false,
    }
  },
  methods: {
    userLogin: function(e) {
      e.stopPropagation()
      if ($('.account-dropdown').length == 1) {
        $('.account-dropdown').toggleClass('open')
        $('.navbar-collapse').removeClass('in')
      } else {
        window.location.href='/customer'
      }
    },
  }
}
</script>