/* global locale */

import { createApp } from 'vue'
import TopNav from './top-nav'
import { createI18n } from 'vue-i18n'
import { messages } from 'default/translated_text'

document.addEventListener('turbo:load', () => {
  if (document.getElementById('topBar')) {
    const i18n = createI18n({ locale, messages, fallbackLocale: 'en' })
    const app = createApp({
      el: '#topBar',
      components: { TopNav },
      template: '<TopNav/>'
    })
    app.use(i18n)
    //app.mount(document.getElementById('topBar'))
  }
})
