import VueLoader from '../default/vue-loader'
import TicketAndHotelIcon from '../shared/add_to_cart_forms/h4h_hotels/icons/hotel-icons'


document.addEventListener('turbo:load', () => {
  let iconsDiv = document.getElementById('f1TicketAndHotelIcons')
  if(iconsDiv){
    VueLoader.loadVueComponent(TicketAndHotelIcon, iconsDiv)
  }
})
