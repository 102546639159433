import FilterTable from './pricing_filter_table'
import VueDropdown from './dropdown'
import {urlParam} from '../../default/category_seating_chart'

let buttons = {}

const findMaxPrice = function(btnID) {
  let div = document.getElementById(btnID)
  if (div == null) { return Infinity }

  return Number(div.dataset.minprice) || Infinity
}

const loadPrices = function() {
  $('.price-ranges .price_range').each(function() {
    buttons[this.id] = {
      minprice: Number(this.dataset.minprice) || -1,
      maxprice: findMaxPrice(this.dataset.maxpricebtn),
    }
  })
  VueDropdown.loadTextDisplays(buttons)
}

const showPrice = function(button) {
  if (button.classList.contains('selected')) {
    button.classList.remove('selected')
    buttons[button.id].selected = false
  } else {
    button.classList.add('selected')
    buttons[button.id].selected = true
  }

  FilterTable.updateDisaplyedRows(buttons)
}

$.tableRowsList = categoryName =>
  $(`[data-category-names*='${categoryName}']`).filter(function() {
    return $(this).data('categoryNames').indexOf(categoryName) > -1
  })

const updateTable = function(categoryName) {
  $('.table tbody tr[data-price]').addClass('hidden')
  $('.price-filter-title, .price-ranges, #currency').hide()

  let rows = $.tableRowsList(categoryName)

  if (rows[0]) {
    rows.removeClass('hidden')
    rows.next('.details_row').removeClass('hidden')
    $.changeTableColors()
  }
}

export default {
  loadPricingFilters() {
    const category = urlParam('package-group')
    if (category) {
      updateTable(category)
    } else {
      $('.table tbody tr[data-price]').removeClass('hidden')
      VueDropdown.loadVueDropdown()
      loadPrices()
      $('.price-ranges .price_range').bind('click', function () { showPrice(this) })
      document.getElementById('packge-price-filer').classList.remove('hidden')
    }
  }
}
