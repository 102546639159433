import CurrencyDropdown from '../../default/currency_convert/currency-dropdown.vue'
import CurrencyText from './currency_text'
import VueLoader from '../../default/vue-loader'

let vueDropDown = null

const addVueListener = function() {
  vueDropDown.$children[0].$on('currency-emit', (errors, currency, rate) => {
    CurrencyText.updateCurrencyAndRate(currency, rate)
  })
}

const setCurrencyVariables = function() {
  vueDropDown = VueLoader.loadVueComponent(CurrencyDropdown,
    document.getElementById('currency-dropdown'))
}

export default {
  loadVueDropdown() {
    let dropDown = document.getElementById('currency-dropdown')

    if (dropDown) {
      setCurrencyVariables(dropDown)
      addVueListener()
    }

    CurrencyText.checkCurrentRates()
  },
  loadTextDisplays(pricesHash) {
    CurrencyText.loadTextDisplays(pricesHash)
  }
}
