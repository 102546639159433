function GetURLParameter(sParam) {
  var sPageURL = window.location.search.substring(1)
  var sURLVariables = sPageURL.split('&')
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=')
    if (sParameterName[0] == sParam) {
      return sParameterName[1]
    }
  }
}

$(document)
  .on('turbo:load', function() {
    let allowedHost = [
      'tickets.formula1.com'
    ]

    var utm = localStorage.getItem('utm')
    utm = utm || GetURLParameter('utm_source')
    if (utm !== undefined && utm.includes(allowedHost)) {
      localStorage.setItem('utm', utm)
      $('.platinium-btn').removeClass('hidden')
    }
    window.onbeforeunload = function() {
      localStorage.removeItem('utm')
    }
  })

  .on('click', '.platinium-btn', function(){
    localStorage.removeItem('utm')
  })
