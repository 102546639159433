import CurrencyApi from '../../default/currency_convert/currency-api'

let exchangeRate = 1
let currency = 'USD'
let priceDivs = {}

const findPriceTextAmount = function(key, div) {
  if (div.dataset.price == 'minprice') {
    return priceDivs[key].minprice * exchangeRate
  } else if (div.dataset.price == 'maxprice') {
    return (priceDivs[key].maxprice * exchangeRate) - 1
  } else {
    return (Number(div.dataset.price) || 0) * exchangeRate
  }
}

const setHTML = function(amount) {
  return CurrencyApi.formatAmountNoCents(amount, currency)
}

const setText = function(key) {
  let div = document.getElementById(key)

  $(div).find('.price-text').each(function() {
    let amount = findPriceTextAmount(key, this)
    this.innerHTML = setHTML(amount)
  })
}

const updateHTML = function() {
  Object.keys(priceDivs).forEach(key => { setText(key) })
}

export default {
  checkCurrentRates() {
    if (currency == 'USD') {
      exchangeRate = 1
    } else {
      CurrencyApi.checkCurrentRates(currency, exchangeRate).then((currentRate) => {
        currency = currentRate.currency
        exchangeRate = Number(currentRate.rate) || 'Currency not available'
      })
    }
  },
  loadTextDisplays(pricesHash) {
    priceDivs = pricesHash
    updateHTML()
  },
  updateCurrencyAndRate(newCurrency, rate) {
    currency = newCurrency
    exchangeRate = rate
    updateHTML()
  }
}
