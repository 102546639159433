/* global locale */

import { createApp } from 'vue'
import MobilePartnerNav from './mobile-partner-nav'
import { createI18n } from 'vue-i18n'
import { messages } from 'default/translated_text'

document.addEventListener('turbo:load', () => {
  if (document.getElementById('mobilePartnerNav')) {
    const i18n = createI18n({ locale, messages, fallbackLocale: 'en' })
    const app = createApp({
      i18n,
      el: '#mobilePartnerNav',
      components: { MobilePartnerNav },
      template: '<MobilePartnerNav/>'
    })
    app.mount()
  }
})
