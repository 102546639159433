<template>
  <div class="f1-top-bar">
    <div class="navbar-container">
      <div class="col-md-3 partner-link-container">
        <div class="partner-links">
          <span><a
            class="f1-link"
            href="https://www.formula1.com/"
            target="_blank"
          >F1<sup>&reg;</sup></a></span>
          <span><a
            class="f2-link"
            href="https://www.fiaformula2.com/"
            target="_blank"
          >F2<sup>TM</sup></a></span>
          <span><a
            class="f3-link"
            href="https://www.fiaformula3.com/"
            target="_blank"
          >F3<sup>TM</sup></a></span>
        </div>
      </div>
      <div class="col-md-9 vendor-links">
        <div class="product-links">
          <!-- eslint-disable vue/no-v-html -->
          <span><a
            href="https://www.f1authentics.com/"
            target="_blank"
            v-html="$t('topBar.authentics')"
          /></span>
          <span><a
            href="https://f1store2.formula1.com/"
            target="_blank"
            v-html="$t('topBar.store')"
          /></span>
          <span><a
            href="https://tickets.formula1.com/"
            target="_blank"
            v-html="$t('topBar.tickets')"
          /></span>
          <span><a
            href="https://tickets.formula1.com/en/4281-formula-one-paddock-club-tickets/"
            target="_blank"
            v-html="$t('topBar.hospitality')"
          /></span>
          <span class="f1-experiences"><a
            href="https://f1experiences.com/"
            v-html="$t('topBar.experiences')"
          /></span>
          <span class="f1-tv"><a
            href="https://f1tv.formula1.com/"
            target="_blank"
          ><img src="../../../assets/images/f1/f1-tv-logo.svg"></a>
          </span>
          <!--eslint-enable-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneNumber from '../../shared/phone.js'

export default {
  data() {
    return {
      phoneNumber: '',
      scrolled: false,
      viewWidth: 0
    }
  },
  mounted: function() {
    this.phoneNumber = PhoneNumber()
    $('.navbar-right').append(`<li class="menu-item phone-contact"><a href="tel:${this.phoneNumber}"><div class="original">Questions? Call Us</div></a></li>`)
  },
  methods: {
    userLogin: function(e) {
      e.stopPropagation()
      if ($('.account-dropdown').length == 1) {
        $('.account-dropdown').toggleClass('open')
      } else {
        window.location.href='/customer'
      }
    },
  }
}
</script>
